@import 'dashboard/scss/variables/_colors.scss';
@import 'dashboard/scss/_common.scss';

#feedback-button-container {
  @include flex-centered;
  height: 50px;
  width: 50px;
}

#feedback-icon {
  fill: #979797;
}
