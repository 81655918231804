$grey-0: #808080;
$grey-1: #cdd8ec;
$grey-2: #f1f1f1;
$grey-3: #b5b5b5;
$xq-blue: #0084ff;
$white: #fff;

$input-black: #202020;
$current-user-blue: #292f4c;
$recipient-user-white: #ffffff;
$client-background-grey: #fbfbfb;
$error-red: #ed4337;

$default-primary: #2a304a;

$title-dark-blue: #111d4c;

$xq-blue: #0084ff;

$primary-button-color: hsla(216, 92%, 59%, 0.85);
