.link-group-icon {
  height: 60px;
  width: 61px;
  border-radius: 10px;
  padding: 7px;

  &.feedback-focused {
    background-color: white;
    border: none;
  }

  &.clicked {
    background-color: #0082ff;
    border: 5px solid #0082ff;
  }

  &.static {
    background-color: #fafafa;
    border: 5px solid #fafafa;
    fill: #979797;

    &.stroke {
      fill: transparent;
      stroke: #979797;

      &:hover {
        stroke: #0082ff;
        fill: none;
      }
    }

    &:hover {
      fill: #0082ff;

      & ~ .link-group-text,
      & ~ .link-group {
        color: #0082ff !important;
      }
    }
  }
}

.link-group-text {
  width: 100%;
  height: 16px;
  font-family: 'proxima-nova';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #94979b;
  margin-top: 10px;
}

.feedback-icon {
  height: 55px;
  width: 56px;
  border-radius: 10px;
  padding: 7px;

  &.static {
    fill: #979797;

    &:hover {
      fill: #0082ff;

      & ~ .feedback-text {
        color: #0082ff;
      }
    }
  }
}

.feedback-text {
  width: 100%;
  height: 16px;
  font-family: 'proxima-nova';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #94979b;
  margin-top: 10px;
}
