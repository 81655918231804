@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
.img-wrap{position:relative;display:inline-block;font-size:0;height:70px;width:70px}.img-wrap .imgact{height:60px;margin-right:5px}.img-wrap .close{position:absolute;top:2px;right:2px;z-index:100;background-color:#fff;padding:5px 2px 2px;color:#000;font-weight:bold;cursor:pointer;opacity:.2;text-align:center;font-size:22px;line-height:10px;border-radius:50%}.img-wrap:hover .close{opacity:1}
.destination-marker-container:hover{cursor:"pointer"}.destination-marker--dotted-line{width:25px;height:25px;position:"absolute";-webkit-transform:translate(15px, 0px) rotate(60deg);transform:translate(15px, 0px) rotate(60deg)}.destination-marker--icon{position:absolute;width:40px;height:40px;-webkit-transform:translate(0px, -40px);transform:translate(0px, -40px)}.destination-marker--icon-origin{position:absolute;width:40px;height:40px;-webkit-transform:translate(28px, -25px);transform:translate(28px, -25px)}.destination-marker--count{font-size:12px;font-weight:bold;color:#fff;position:relative;-webkit-transform:translate(-5px, -40px);transform:translate(-5px, -40px)}.destination-marker--count-origin{font-size:12px;font-weight:bold;color:#fff;position:relative;-webkit-transform:translate(14px, -15px);transform:translate(14px, -15px)}
.mapboxgl-ctrl-compass{display:none !important}.alert--no-logs{border-radius:10px;margin-top:10px;color:#fff;background-color:#0084ff !important}.alert-container{display:flex;justify-content:center}
.link-group-icon{height:60px;width:61px;border-radius:10px;padding:7px}.link-group-icon.feedback-focused{background-color:#fff;border:none}.link-group-icon.clicked{background-color:#0082ff;border:5px solid #0082ff}.link-group-icon.static{background-color:#fafafa;border:5px solid #fafafa;fill:#979797}.link-group-icon.static.stroke{fill:rgba(0,0,0,0);stroke:#979797}.link-group-icon.static.stroke:hover{stroke:#0082ff;fill:none}.link-group-icon.static:hover{fill:#0082ff}.link-group-icon.static:hover~.link-group-text,.link-group-icon.static:hover~.link-group{color:#0082ff !important}.link-group-text{width:100%;height:16px;font-family:"proxima-nova";font-size:12px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:normal;text-align:center;color:#94979b;margin-top:10px}.feedback-icon{height:55px;width:56px;border-radius:10px;padding:7px}.feedback-icon.static{fill:#979797}.feedback-icon.static:hover{fill:#0082ff}.feedback-icon.static:hover~.feedback-text{color:#0082ff}.feedback-text{width:100%;height:16px;font-family:"proxima-nova";font-size:12px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:normal;text-align:center;color:#94979b;margin-top:10px}
.scrollbar-without-transition{overflow-x:hidden;margin-bottom:4px;margin-top:4px;margin-right:4px;scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);scrollbar-width:thin}.scrollbar-without-transition:hover{scrollbar-color:#adafb3 rgba(0,0,0,0)}.scrollbar-without-transition:hover::-webkit-scrollbar-thumb{background-color:#adafb3}.scrollbar-without-transition::-webkit-scrollbar-thumb{background-color:inherit;border-radius:14px}.scrollbar-without-transition::-webkit-scrollbar{width:6px}.scrollbar-without-transition::-webkit-scrollbar-track{display:none}
#navigation-sidebar-container{box-shadow:0 0 40px -20px rgba(1,28,53,.45);display:flex;z-index:500}#feedback-button-title{width:100%;height:16px;font-size:12px;font-weight:500;text-align:center;color:#94979b;margin-top:10px}
input[type=search]::-ms-clear{display:none;width:0;height:0}input[type=search]::-ms-reveal{display:none;width:0;height:0}input[type=search]::-webkit-search-decoration,input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-results-button,input[type=search]::-webkit-search-results-decoration{display:none}
.overrides{width:100%}.overrides>div>div{border-right:1px solid #adafb3}.overrides>input{width:447px;height:48px;border:1px solid #adafb3;box-sizing:border-box;border-radius:6px;width:100%;font-family:"proxima-nova";font-style:normal;font-weight:400;font-size:18px;line-height:18px;color:#38393e}.overrides>input:focus{outline:none;border-color:#0084ff}.overrides>input::-webkit-input-placeholder{font-family:"proxima-nova";font-style:normal;font-weight:400;font-size:18px;line-height:18px;color:#adafb3}.overrides>input:-ms-input-placeholder{font-family:"proxima-nova";font-style:normal;font-weight:400;font-size:18px;line-height:18px;color:#adafb3}.overrides>input::placeholder{font-family:"proxima-nova";font-style:normal;font-weight:400;font-size:18px;line-height:18px;color:#adafb3}@media screen and (max-width: 1234px){.overrides>input{height:40px}}.overrides--error>input{border-color:#e53e3e}
#invite-guest-container{max-width:465px;margin-left:auto;margin-right:auto}#invite-guest-heading{font-family:"proxima-nova";font-weight:600;font-size:17px;padding-bottom:11px;color:#111d4c}#invite-guest-body{font-family:"proxima-nova";font-weight:400;font-size:15px;padding-bottom:30px;color:#111d4c}#invite-guest-start-button{width:100%;background-color:rgba(54,131,247,.85)}@media screen and (max-width: 800px){#invite-guest-container{margin-top:19px}}
.main-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.clickable:hover {
  cursor: pointer;
}

.message-list-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: scroll;
  padding: 20px 0 50px 0;
  width: 100%;
}

.message-container {
  display: inline-block;
}

.mobile-message-list-container {
  bottom: 5em;

  position: fixed;
  overflow: scroll;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  padding-top: 25px;
  padding-bottom: 50px;
  background-color: #fbfbfb;
}

.mobile-message-container {
  display: inline-block;
  margin-top: 8px;
  position: relative;
}

#notification-count-container{align-items:center;display:flex;flex-direction:row;justify-content:center;background-color:#ed4337;border-radius:10px;font-size:10px;color:#fff;height:20px;padding-top:1px;width:20px}
.avatar-overlay{position:absolute;width:110px;height:110px;border-radius:55px;top:0;z-index:2;justify-content:center;align-items:center;cursor:pointer}.camera-icon{width:20px;height:20px}
#compose-pill{align-items:center;background-color:#0084ff;border-radius:25px;border:none;display:flex;height:50px;padding-left:15px;position:fixed;transition:width .3s ease;width:50px}.button-text{display:none}@media screen and (min-width: 800px){#compose-pill{bottom:35px;right:19px}#compose-pill:hover,#compose-pill:focus{width:145px;justify-content:flex-start}#compose-pill:hover .button-text,#compose-pill:focus .button-text{color:#fff;display:inline;margin-left:12px;text-transform:uppercase;font-weight:600;font-size:16px}}@media screen and (max-width: 800px){#compose-pill{bottom:10px;right:15px}}
#feedback-button-container{align-items:center;display:flex;flex-direction:row;justify-content:center;height:50px;width:50px}#feedback-icon{fill:#979797}
@keyframes fadeInUp{from{-webkit-transform:translate3d(0, 40px, 0);transform:translate3d(0, 40px, 0)}to{-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);opacity:1}}@-webkit-keyframes fadeInUp{from{transform:translate3d(0, 40px, 0);-webkit-transform:translate3d(0, 40px, 0);-moz-transform:translate3d(0, 40px, 0)}to{transform:translate3d(0, 0, 0);-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);opacity:1}}.animated{animation-duration:300ms;animation-fill-mode:both;-webkit-animation-duration:300ms;-webkit-animation-fill-mode:both;-moz-animation-duration:300ms;-moz-webkit-animation-fill-mode:both}.animatedFadeInUp{opacity:0}.fadeInUp{opacity:0;animation-name:fadeInUp;-webkit-animation-name:fadeInUp;-moz-webkit-animation-name:fadeInUp}.markdown{white-space:"pre-wrap";width:100%;line-height:25px}.markdown ul,.markdown ol{margin:5px 0 5px 20px}.markdown li,.markdown p{margin:5px 0}.markdown pre{display:flex;margin:5px 0;width:100%}.markdown code{background-color:#292d3e;color:#c27f69;border:1px solid #c27f69;border-radius:3px;padding:2px 5px;font-size:12px;word-wrap:break-word;word-break:break-all;padding:5px;white-space:"pre-wrap";width:100%;margin:10px 2px}
#sidebar-container{padding:16px 11px 50px 11px;position:relative;margin-top:0}@media screen and (min-width: 800px){#sidebar-container{overflow-x:hidden;overflow-y:auto;height:calc(100% - 1.5em)}}
.main-container{border:none;border-radius:6px;box-shadow:0 5px 15px 0 rgba(16,27,79,.15);padding-top:16px;max-height:500px}.title-text{color:#101b4f;font-size:14px;font-weight:600;overflow-wrap:break-word}.trigger-container{align-items:center;cursor:pointer;display:flex;justify-content:center}.popover-body{overflow-x:hidden;margin-bottom:4px;margin-top:4px;margin-right:4px;scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);scrollbar-width:thin;overflow-y:scroll}.popover-body:hover{scrollbar-color:#adafb3 rgba(0,0,0,0)}.popover-body:hover::-webkit-scrollbar-thumb{background-color:#adafb3}.popover-body::-webkit-scrollbar-thumb{background-color:inherit;border-radius:14px}.popover-body::-webkit-scrollbar{width:6px}.popover-body::-webkit-scrollbar-track{display:none}.popover-body-item{align-items:center;border-radius:5px;display:flex;cursor:pointer;margin:0 0 16px 0;padding:5px}.popover-body-item{align-items:center;border-radius:5px;display:flex;cursor:pointer;margin:0 0 16px 0;padding:5px;z-index:9999}.popover-body-item.selected{background-color:#b0d7fb}.item-text{color:"#000";font-size:15px;font-weight:500}.avatar{margin-right:15px}
.profile-button{background-color:#fff;-webkit-filter:drop-shadow(6px 4px 10px rgba(235, 237, 238, 0.8));filter:drop-shadow(6px 4px 10px rgba(235, 237, 238, 0.8));border-radius:10px;color:#000;display:flex;justify-content:center;align-items:center;height:60px;width:60px}.active:hover{-webkit-filter:brightness(92%);filter:brightness(92%)}
#header-container{align-items:center;display:flex;flex-direction:row;justify-content:center;background-color:#fff;box-shadow:0px 10px 30px 10px rgba(235,237,238,.5);height:80px;width:100%}
#mobile-header-container{align-items:center;display:flex;flex-direction:row;justify-content:center;flex:1 1;justify-content:space-between;padding:0 8px}
.message-input--mobile{position:fixed;bottom:0;background-color:#fbfbfb;z-index:201}#toolbar{display:flex;justify-content:space-between}.blurred:hover{cursor:text}.code-block{display:flex;background-color:#292d3e;color:#c27f69;border:1px solid #c27f69;border-radius:3px;padding:5px;font-size:12px;width:100%;margin:5px 0}.DraftEditor-root{overflow-x:hidden;margin-bottom:4px;margin-top:4px;margin-right:4px;scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);scrollbar-width:thin;padding:5px 10px;min-height:unset;overflow-y:auto;max-width:100%;resize:none;overflow-wrap:break-word;line-height:25px}.DraftEditor-root:hover{scrollbar-color:#adafb3 rgba(0,0,0,0)}.DraftEditor-root:hover::-webkit-scrollbar-thumb{background-color:#adafb3}.DraftEditor-root::-webkit-scrollbar-thumb{background-color:inherit;border-radius:14px}.DraftEditor-root::-webkit-scrollbar{width:6px}.DraftEditor-root::-webkit-scrollbar-track{display:none}.DraftEditor-root ul,.DraftEditor-root ol{margin-left:20px}.DraftEditor-root .public-DraftStyleDefault-pre pre{white-space:"pre-wrap";margin:5px 2px}.DraftEditor-root a{color:#6997f4;text-decoration:underline}.DraftEditor-root p{display:inline}
.option-menu-container{align-items:center;display:flex;flex-direction:row;justify-content:center;background-color:#fff;border:solid 1px #000;border-radius:6px;height:50px;width:50px;position:absolute;top:-15px;z-index:5}.is-sender{left:-10px}.is-recipient{right:0}#icon-container{align-items:center;display:flex;flex-direction:row;justify-content:center;height:100%;flex:1 1}
.LocatorToken_main-container__1UE26{display:flex;width:99%;padding:.75rem}.LocatorToken_container-current-user__31Eha{flex-direction:row-reverse}.LocatorToken_text__2pBRb{font-size:14px;letter-spacing:-0.34px;color:#94979b;margin-left:15px}.LocatorToken_text-current-user__k3lLr{margin-left:0;margin-right:15px}
.message-list-mobile{overflow-y:auto;-ms-scroll-chaining:none;overscroll-behavior:contain;margin-top:calc(5em + 15px);width:100%;margin-right:4px;transition:all .2s ease .5s;color:rgba(0,0,0,0);scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);scrollbar-width:thin}.message-list-mobile:hover{transition:all .2s ease 0s;color:#adafb3;scrollbar-color:#adafb3 rgba(0,0,0,0)}.message-list-mobile::-webkit-scrollbar-thumb{box-shadow:inset 0 0 0 20px;border-radius:14px}.message-list-mobile::-webkit-scrollbar{-webkit-appearance:none;appearance:none;width:6px}.message-list{padding-top:15px;overflow-y:auto;-ms-scroll-chaining:none;overscroll-behavior:contain;width:100%;margin-right:4px;transition:all .2s ease .5s;color:rgba(0,0,0,0);scrollbar-color:rgba(0,0,0,0) rgba(0,0,0,0);scrollbar-width:thin}.message-list:hover{transition:all .2s ease 0s;color:#adafb3;scrollbar-color:#adafb3 rgba(0,0,0,0)}.message-list::-webkit-scrollbar-thumb{box-shadow:inset 0 0 0 20px;border-radius:14px}.message-list::-webkit-scrollbar{-webkit-appearance:none;appearance:none;width:6px}.safari{transition:all .2s ease .5s}.safari:hover{transition:all .2s ease 0s}.safari:hover::-webkit-scrollbar-thumb{background-color:#adafb3}.safari::-webkit-scrollbar-thumb{background-color:inherit}

