#navigation-sidebar-container {
  box-shadow: 0 0 40px -20px rgba(1, 28, 53, 0.45);
  display: flex;
  z-index: 500;
}

#feedback-button-title {
  width: 100%;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #94979b;
  margin-top: 10px;
}
