@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    -webkit-transform: translate3d(0, 40px, 0);
    -moz-transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 300ms;
  animation-fill-mode: both;
  -webkit-animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 300ms;
  -moz-webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-webkit-animation-name: fadeInUp;
}

.markdown {
  white-space: 'pre-wrap';
  width: 100%;
  line-height: 25px;

  ul,
  ol {
    margin: 5px 0 5px 20px;
  }

  li,
  p {
    margin: 5px 0;
  }

  pre {
    display: flex;
    margin: 5px 0;
    width: 100%;
  }

  code {
    background-color: #292d3e;
    color: #c27f69;
    border: 1px solid #c27f69;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 12px;
    word-wrap: break-word;
    word-break: break-all;
    padding: 5px;
    white-space: 'pre-wrap';
    width: 100%;
    margin: 10px 2px;
  }
}
