.profile-button {
  background-color: white;
  filter: drop-shadow(6px 4px 10px rgba(235, 237, 238, 0.8));
  border-radius: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

.active {
  &:hover {
    filter: brightness(92%);
  }
}
