@mixin scrollbar-with-transition {
  margin-right: 4px;
  transition: all 0.2s ease 0.5s;

  &:hover {
    transition: all 0.2s ease 0s;
    color: hsl(220, 4%, 69%);
    // firefox
    scrollbar-color: hsl(220, 4%, 69%) transparent;
  }

  color: transparent;

  &::-webkit-scrollbar-thumb {
    /* The box-shadow inherits the text color by default. */
    box-shadow: inset 0 0 0 20px;
    border-radius: 14px;
  }

  &::-webkit-scrollbar {
    appearance: none;
    width: 6px;
  }
  // firefox
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
@mixin scrollbar-without-transition {
  overflow-x: hidden;
  margin-bottom: 4px;
  margin-top: 4px;
  margin-right: 4px;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: hsl(220, 4%, 69%);
    }
    // firefox
    scrollbar-color: hsl(220, 4%, 69%) transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: inherit;
    border-radius: 14px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  // firefox
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
