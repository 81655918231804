@import 'dashboard/scss/_common.scss';

.option-menu-container {
  @include flex-centered;
  background-color: white;
  border: solid 1px #000000;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -15px;
  z-index: 5;
}

.is-sender {
  left: -10px;
}

.is-recipient {
  right: 0;
}

#icon-container {
  @include flex-centered;
  height: 100%;
  flex: 1;
}
