@import 'dashboard/scss/mixins.scss';

.main-container {
  border: none;
  border-radius: 6px;
  box-shadow: 0 5px 15px 0 rgba(16, 27, 79, 0.15);
  padding-top: 16px;
  max-height: 500px;
}

.title-text {
  color: #101b4f;
  font-size: 14px;
  font-weight: 600;
  overflow-wrap: break-word;
}

.trigger-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.popover-body {
  @include scrollbar-without-transition;
  overflow-y: scroll;
}

.popover-body-item {
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  margin: 0 0 16px 0;
  padding: 5px;
}

.popover-body-item {
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  margin: 0 0 16px 0;
  padding: 5px;
  z-index: 9999;
}

.popover-body-item.selected {
  background-color: #b0d7fb;
}

.item-text {
  color: '#000';
  font-size: 15px;
  font-weight: 500;
}

.avatar {
  margin-right: 15px;
}
