.main-container {
  display: flex;
  width: 99%;
  padding: 0.75rem;
}

.container-current-user {
  flex-direction: row-reverse;
}

.text {
  font-size: 14px;
  letter-spacing: -0.34px;
  color: #94979b;
  margin-left: 15px;
}

.text-current-user {
  margin-left: 0;
  margin-right: 15px;
}
