@import 'dashboard/scss/variables/_colors.scss';

#invite-guest-container {
  max-width: 465px;
  margin-left: auto;
  margin-right: auto;
}

#invite-guest-heading {
  font-family: 'proxima-nova';
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 11px;
  color: $title-dark-blue;
}

#invite-guest-body {
  font-family: 'proxima-nova';
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 30px;
  color: $title-dark-blue;
}

#invite-guest-start-button {
  width: 100%;
  background-color: $primary-button-color;
}

@media screen and (max-width: 800px) {
  #invite-guest-container {
    margin-top: 19px;
  }
}
