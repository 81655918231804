.img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
  height: 70px;
  width: 70px;
}

.img-wrap .imgact {
  height: 60px;
  margin-right: 5px;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #fff;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.img-wrap:hover .close {
  opacity: 1;
}
