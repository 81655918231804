@import 'dashboard/scss/mixins.scss';

.message-list-mobile {
  overflow-y: auto;
  overscroll-behavior: contain;
  margin-top: calc(5em + 15px);
  width: 100%;
  @include scrollbar-with-transition;
}

.message-list {
  padding-top: 15px;
  overflow-y: auto;
  overscroll-behavior: contain;
  width: 100%;
  @include scrollbar-with-transition;
}

.safari {
  transition: all 0.2s ease 0.5s;
  &:hover {
    transition: all 0.2s ease 0s;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: hsl(220, 4%, 69%);
  }
  &::-webkit-scrollbar-thumb {
    background-color: inherit;
  }
}
