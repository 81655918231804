.main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.clickable:hover {
  cursor: pointer;
}

.message-list-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: scroll;
  padding: 20px 0 50px 0;
  width: 100%;
}

.message-container {
  display: inline-block;
}

.mobile-message-list-container {
  bottom: 5em;

  position: fixed;
  overflow: scroll;
  overscroll-behavior: contain;
  padding-top: 25px;
  padding-bottom: 50px;
  background-color: #fbfbfb;
}

.mobile-message-container {
  display: inline-block;
  margin-top: 8px;
  position: relative;
}
