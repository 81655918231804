@import 'dashboard/scss/_common.scss';
@import 'dashboard/scss/variables/_colors.scss';

#notification-count-container {
  @include flex-centered;
  background-color: $error-red;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  height: 20px;
  padding-top: 1px;
  width: 20px;
}
