.overrides {
  width: 100%;

  > div {
    > div {
      border-right: 1px solid hsla(220, 4%, 69%, 1);
    }
  }

  > input {
    width: 447px;
    height: 48px;

    border: 1px solid #adafb3;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    &:focus {
      outline: none;
      border-color: hsl(209, 100%, 50%);
    }

    &::placeholder {
      font-family: 'proxima-nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #adafb3;
    }

    color: hsl(230, 5%, 23%);
    @media screen and (max-width: 1234px) {
      height: 40px;
    }
  }
  &--error {
    // > div {
    //   > div {
    //     border-right-color: hsl(0, 76%, 57%);
    //   }
    // }
    > input {
      border-color: hsl(0, 76%, 57%);
    }
  }
}
