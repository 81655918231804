@mixin count {
  font-size: 12px;
  font-weight: bold;
  color: white;
  position: relative;
}

@mixin icon {
  position: absolute;
  width: 40px;
  height: 40px;
}

.destination-marker {
  &-container {
    &:hover {
      cursor: 'pointer';
    }
  }

  &--dotted-line {
    width: 25px;
    height: 25px;
    position: 'absolute';
    transform: translate(15px, 0px) rotate(60deg);
  }

  &--icon {
    @include icon;
    transform: translate(0px, -40px);

    &-origin {
      @include icon;
      transform: translate(28px, -25px);
    }
  }

  &--count {
    @include count;
    transform: translate(-5px, -40px);

    &-origin {
      @include count;
      transform: translate(14px, -15px);
    }
  }
}
