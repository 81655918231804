@import 'dashboard/scss/variables/_colors.scss';

#sidebar-container {
  padding: 16px 11px 50px 11px;
  position: relative;
  margin-top: 0;
  @media screen and (min-width: 800px) {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 1.5em);
  }
}
