.avatar-overlay {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  top: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.camera-icon {
  width: 20px;
  height: 20px;
}
