@import 'dashboard/scss/variables/_colors.scss';

#compose-pill {
  align-items: center;
  background-color: $xq-blue;
  border-radius: 25px;
  border: none;
  display: flex;
  height: 50px;
  padding-left: 15px;
  position: fixed;
  transition: width 0.3s ease;
  width: 50px;
}

.button-text {
  display: none;
}

// Desktop mode - hover/focus addition
@media screen and (min-width: 800px) {
  #compose-pill {
    bottom: 35px;
    right: 19px;
  }

  #compose-pill:hover,
  #compose-pill:focus {
    width: 145px;
    justify-content: flex-start;

    .button-text {
      color: white;
      display: inline;
      margin-left: 12px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

// Mobile mode - no hover/focus effects, modified positioning
@media screen and (max-width: 800px) {
  #compose-pill {
    bottom: 10px;
    right: 15px;
  }
}
