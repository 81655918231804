.mapboxgl-ctrl-compass {
  display: none !important;
}

.alert--no-logs {
  border-radius: 10px;
  margin-top: 10px;
  color: white;
  background-color: hsl(209, 100%, 50%) !important;
}

.alert-container {
  display: flex;
  justify-content: center;
}
