@import '../../../../scss/variables/colors';
@import '../../../../scss/mixins.scss';
.message-input {
  &--mobile {
    position: fixed;
    bottom: 0;
    background-color: #fbfbfb;
    z-index: 201;
  }
}

#toolbar {
  display: flex;
  justify-content: space-between;
}

.blurred {
  &:hover {
    cursor: text;
  }
}

.code-block {
  display: flex;
  background-color: #292d3e;
  color: #c27f69;
  border: 1px solid #c27f69;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
  width: 100%;
  margin: 5px 0;
}

.DraftEditor-root {
  @include scrollbar-without-transition;
  padding: 5px 10px;
  min-height: unset;
  overflow-y: auto;
  max-width: 100%;
  resize: none;
  overflow-wrap: break-word;
  line-height: 25px;
  ul,
  ol {
    margin-left: 20px;
  }
  .public-DraftStyleDefault-pre pre {
    // white-space: normal;
    white-space: 'pre-wrap';
    margin: 5px 2px;
  }
  a {
    color: #6997f4;
    text-decoration: underline;
  }

  p {
    display: inline;
  }
}
